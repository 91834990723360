<template>
    <v-card>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="team.sprints"
                :items-per-page="20"
                sort-by="name"
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200],
                    'show-current-page': true,
                    'show-first-last-page': true,
                }"
                v-on:click:row="editItem"
                class="table-selector"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer />
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn v-if="allowCreate" rounded color="primary" dark v-on="on">
                                    {{ $t('teams.sprints.actions.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline blue--text text--darken-1">{{ $t('title.newSprint') }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <date-selector
                                        v-model="sprintToCreate.startedAt"
                                        :label="$t('teams.sprints.attributes.startedAt')"
                                    />

                                    <date-selector
                                        v-model="sprintToCreate.endedAt"
                                        :label="$t('teams.sprints.attributes.endedAt')"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.startedAt`]="{ item }">
                    {{ formatDate(item.startedAt) }}
                </template>

                <template v-slot:[`item.endedAt`]="{ item }">
                    {{ formatDate(item.endedAt) }}
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-avatar v-if="item.status === 'new'" color="light-blue" size="36">
                        <v-icon>mdi-decagram</v-icon>
                    </v-avatar>
                    <v-avatar v-if="item.status === 'active'" color="cyan" size="36">
                        <v-icon>mdi-refresh</v-icon>
                    </v-avatar>
                    <v-avatar v-if="item.status === 'close'" color="blue-grey" size="36">
                        <v-icon>mdi-check</v-icon>
                    </v-avatar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDateMixin } from '@/mixins/dates';
import dateSelector from '@/components/selectors/simple/date';
import { parseISO, addDays } from 'date-fns';

export default {
    name: 'team-sprints',
    data: () => ({
        dialog: false,
        sprintToCreate: {
            startedAt: undefined,
            endedAt: undefined,
        },
    }),
    created() {},
    mixins: [formatDateMixin],
    components: { dateSelector },
    watch: {
        dialog: function (isCreate) {
            if (isCreate) {
                if (this.team.sprints.length > 0) {
                    const lastSprint = this.team.sprints[this.team.sprints.length - 1];
                    this.sprintToCreate.startedAt = parseISO(lastSprint.endedAt);
                    this.sprintToCreate.endedAt = addDays(this.sprintToCreate.startedAt, 14);
                }
            }
        },
    },
    computed: {
        ...mapGetters(['team', 'profile']),
        teamUser() {
            if (this.team) {
                return this.team.members.filter((m) => m.user === this.profile.name)[0];
            }
            return undefined;
        },
        allowCreate() {
            if (this.teamUser) {
                return this.teamUser.roles.includes('productManager');
            }
            return false;
        },
        headers() {
            return [
                {
                    text: this.$t('teams.sprints.attributes.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('teams.sprints.attributes.startedAt'),
                    sortable: true,
                    value: 'startedAt',
                },
                {
                    text: this.$t('teams.sprints.attributes.endedAt'),
                    sortable: false,
                    value: 'endedAt',
                },
                {
                    text: this.$t('teams.sprints.attributes.status'),
                    sortable: false,
                    value: 'status',
                },
            ];
        },
    },
    methods: {
        ...mapActions(['addTeamSprint']),
        cancelEdit() {
            this.dialog = false;
        },
        editItem(item) {
            this.$router.push({ name: 'team-sprint', params: { sprint_id: item.id } });
        },
        save() {
            this.addTeamSprint(Object.assign({ team: this.team.id }, this.sprintToCreate));
            this.cancelEdit();
        },
    },
};
</script>
<style scoped>
.table-selector {
    cursor: pointer;
}
</style>
