var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"table-selector",attrs:{"headers":_vm.headers,"items":_vm.team.sprints,"items-per-page":20,"sort-by":"name","footer-props":{
                'items-per-page-options': [10, 20, 50, 100, 200],
                'show-current-page': true,
                'show-first-last-page': true,
            }},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.allowCreate)?_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t('teams.sprints.actions.add'))+" ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline blue--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('title.newSprint')))])]),_c('v-card-text',[_c('date-selector',{attrs:{"label":_vm.$t('teams.sprints.attributes.startedAt')},model:{value:(_vm.sprintToCreate.startedAt),callback:function ($$v) {_vm.$set(_vm.sprintToCreate, "startedAt", $$v)},expression:"sprintToCreate.startedAt"}}),_c('date-selector',{attrs:{"label":_vm.$t('teams.sprints.attributes.endedAt')},model:{value:(_vm.sprintToCreate.endedAt),callback:function ($$v) {_vm.$set(_vm.sprintToCreate, "endedAt", $$v)},expression:"sprintToCreate.endedAt"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelEdit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.startedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startedAt))+" ")]}},{key:"item.endedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endedAt))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status === 'new')?_c('v-avatar',{attrs:{"color":"light-blue","size":"36"}},[_c('v-icon',[_vm._v("mdi-decagram")])],1):_vm._e(),(item.status === 'active')?_c('v-avatar',{attrs:{"color":"cyan","size":"36"}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e(),(item.status === 'close')?_c('v-avatar',{attrs:{"color":"blue-grey","size":"36"}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }